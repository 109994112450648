<template>
    <div class="account-orders-page">
        <el-card class="basic-card order-list-card">
            <div slot="header" class="flex flex-align-center">
                <div class="padding-t-10 padding-d-15">我的订单</div>
                <div class="flex-1 flex flex-justify-end">
                   <!--  <div>
                        <el-date-picker style="width: 228px; border-radius: 50px;"
                            v-model="dates"
                            type="daterange"
                            range-separator="-"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            size="small" round>
                        </el-date-picker>
                    </div> -->
                    <div class="margin-l-10">
                        <el-button plain round size="small" @click="invoicingEvent"><svg-icon name="ib-invoicing"></svg-icon> 申请开票</el-button>
                    </div>
                </div>
            </div>
            <el-table :data="orderList" style="width: 100%;">
              <!-- <el-table-column type="selection" width="45"></el-table-column> -->
              <el-table-column label="订单号" prop="orderNo"></el-table-column>
              <el-table-column label="订单生成日期" prop="createTime" width="170"></el-table-column>
              <el-table-column label="购买商品">
                  <template slot-scope="scope">
                      {{scope.row.paymentMethod}}
                  </template>
              </el-table-column>
              <el-table-column label="支付类型" prop="paymentType" width="100"></el-table-column>
              <el-table-column label="支付金额" width="110">
                  <template slot-scope="scope">
                      ￥{{scope.row.amount}}
                  </template>
              </el-table-column>
              <el-table-column label="订单状态" width="80">
                  <template slot-scope="scope">
                      {{scope.row.orderStatus}}
                  </template>
              </el-table-column>
            </el-table>
            <div class="pagination-content margin-t-15" style="margin-bottom: 0;">
                <el-pagination background :total="order.total" :current-page="order.pageNumber" :page-size="order.pageSize" @current-change="handleOrderCurrentChange" layout="total, prev, pager, next"></el-pagination>
            </div>
        </el-card>
        <!-- 
        <el-card class="basic-card margin-t-20">
            <template slot="header">
                <span>发票管理</span>
            </template>
            <el-table :data="invoiceList" style="width: 100%">
              <el-table-column label="发票类型"></el-table-column>
              <el-table-column label="申请人"></el-table-column>
              <el-table-column label="申请时间"></el-table-column>
              <el-table-column label="操作"></el-table-column>
            </el-table>
            <div class="pagination-content margin-t-15" style="margin-bottom: 0;">
                <el-pagination background :total="invoice.total" :current-page="invoice.pageNumber" :page-size="invoice.pageSize" @current-change="handleInvoiceCurrentChange" layout="total, prev, pager, next"></el-pagination>
            </div>
        </el-card>
         -->
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { userApi } from "@/utils/api";
export default {
    data(){
        return {
            dates:[],
            orderList: [],
            order: {
                pageSize: 11,
                pageNumber: 1,
                total: 0,
            },

            invoiceList: [],
            invoice: {
                pageSize: 11,
                pageNumber: 1,
                total: 100,
            }

        }
    },
    computed: {
		...mapGetters({
			userInfo: "user/userInfo",
		}),
	},
    methods:{
        initOrderList(){
            let url = `${userApi.queryOrderList}?userId=${this.userInfo.userId}` +
                        `&pageNumber=${this.order.pageNumber}&pageSize=${this.order.pageSize}`;
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    this.orderList = res.data.records;
                    this.order.total = res.data.total;
                }
            });
        },
        initInvoiceList(){

        },
        handleOrderCurrentChange(val){
            this.order.pageNumber = val;
            this.initOrderList();
        },
        handleInvoiceCurrentChange(val){
            this.invoice.pageNumber = val;
            this.initInvoiceList();
        },
        invoicingEvent() {
            window.open('https://m15er90ta0.feishu.cn/share/base/form/shrcnt4Z1ziC7IVeP7AuTq8ffDc', '_blank');
        }
    },
    mounted(){
        this.initOrderList();
        this.initInvoiceList();
    }
}
</script>
<style lang="scss" scoped>
.account-orders-page{
    .basic-card{
        box-shadow: initial;
    }
    .order-list-card{
        ::v-deep .el-card__header{
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
}
</style>
